import React, { useEffect, useRef, useState } from 'react'
import CreatePurchases from './CreatePurchases'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi } from '../../api'
import { debounce, get } from 'lodash'
import _ from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import moment from 'moment'
import { useSelector } from 'react-redux'
import CreateSuppliersModal from '../../components/Modal/CreateSuppliers'
import ConfirmModal from '../../components/ConfirmModal/index'
import customMainApi from '../../api/index'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'

const CreatePurchasesPage = () => {
	const { getMe } = useSelector((state) => state.main)

	const succesCreateUserRef = useRef()
	const createUserRef = useRef()
	const navigate = useNavigate()
	const errorRef = useRef()
	const inputRef = useRef(null)
	const [businessPartnersData, setBusinessPartnersData] = useState([])
	const [itemsData, setItemsData] = useState([])
	const [selectedItem, setSelectedItem] = useState([])
	const [whichInput, setWhichInput] = useState(null)
	const [loadingBusinessPartners, setLoadingBusinessPartners] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [userFields, setUserFields] = useState([])
	const [batteryLife, setBatteryLife] = useState('')
	const [condition, setCondition] = useState('')

	const [confirmationMessage, setConfirmationMessage] = useState('')

	const [form, setForm] = useState({
		CardCode: '',
		// DocDate: moment().format('YYYY-MM-DD') + 'T00:00:00',
		CardName: '',
		DocCurrency: 'USD',
		DocumentLines: [],
	})

	const [createUserLoading, setCreateUserLoading] = useState(false)
	const initItem = {
		ItemCode: '',
		Quantity: 0,
		UnitPrice: 0,
		SerialNumbers: '',
		U_IMEI_PURCH: 'IMEI',
		U_warranty: '',
		batteryLife: '',
		U_Item_Region: '',
		SerialNumber2: '',
	}
	const [items, setItems] = useState([
		{
			...initItem,
		},
	])
	const [itemsName, setItemsName] = useState([''])
	const [date, setDate] = useState(dayjs())

	const [btnLoading, setBtnLoading] = useState(false)

	const getBusinessPartners = async (name = '') => {
		if (name.length <= 0) {
			setBusinessPartnersData([])
			return
		}
		setLoadingBusinessPartners(true)
		//  and GroupCode eq '101'
		const data = await api(
			`BusinessPartners?$select=CardCode,CardName,GroupCode&$filter=CardType eq 'cSupplier' and GroupCode eq 101 and contains(CardName,'${name}')`,
		).then((res) => {
			return JSON.parse(res.data).value
		})

		const selectElementData = data.map((item) => {
			return {
				value: item.CardCode,
				label: item.CardName,
			}
		})

		setBusinessPartnersData(selectElementData)
		setLoadingBusinessPartners(false)
	}

	const createUserF = (consumer, passport, male, address, phone, cardCode) => {
		setCreateUserLoading(true)
		api
			.post(`BusinessPartners`, {
				CardCode: cardCode,
				CardName: consumer,
				CardType: 'cSupplier',
				U_Telephone: phone,
				U_Gender: male,
				U_PS: passport,
				Currency: '##',
			})
			.then(() => {
				createUserRef.current?.close()
				succesCreateUserRef.current?.open('Пользователь успешно создан')
			})
			.catch((err) => {
				errorRef.current?.open(
					get(
						JSON.parse(err.response.data),
						'error.message',
						'Не удалось получить данные, попробуйте еще раз.',
					),
				)
			})
			.finally(() => {
				setCreateUserLoading(false)
			})
	}

	const purchaseInvoicesPost = async () => {
		const uniqueItems = []
		items.forEach((item) => {
			{
				uniqueItems.push({
					ItemCode: item.ItemCode,
					Quantity: 1,
					SerialNumbers: [
						{
							InternalSerialNumber: item.SerialNumbers,
							// POSManufacturerSerialNumber: item.SerialNumber2,
						},
					],
					UnitPrice: item.UnitPrice,
					U_IMEI_PURCH: item.U_IMEI_PURCH,
					U_Percent: item.batteryLife,
					U_warranty: Number(item.U_warranty),
					...(get(getMe, 'Department2.Name', '') !== 'Boshqaruv'
						? { WarehouseCode: get(getMe, 'U_Warehouse', '') }
						: {}),
					U_Region_Item: item.U_Item_Region,
				})
			}
		})
		const formatedDate = {
			...form,
			DocDate: dayjs(date).format('YYYY-MM-DD') + 'T00:00:00',
			SalesPersonCode: get(getMe, 'SalesPersonCode', -1),
			DocumentsOwner: get(getMe, 'EmployeeID', null),

			DocumentLines: uniqueItems,
		}

		setBtnLoading(true)

		try {
			await api.post(`PurchaseInvoices`, formatedDate).then((res) => {
				return JSON.parse(res.data).value
			})
			navigate('/purchases')
		} catch (e) {
			if (e) {
				errorRef.current?.open(
					get(JSON.parse(e.response.data), 'error.message', ''),
				)
			}
		} finally {
			setBtnLoading(false)
		}
	}

	const getItems = async (name = '', i) => {
		if (name.length <= 0) {
			setItemsData([])
			return
		}
		setWhichInput(i)
		const data = await api(
			`Items?$select=ItemName,ItemCode,U_Condition,U_Memory,U_Color&$filter=Frozen eq 'tNO' and (contains(ItemName,'${name}') or contains(ItemCode,'${name}'))&$orderby=ItemCode asc`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			},
		).then((res) => {
			return JSON.parse(res.data).value
		})

		setItemsData(data)
	}

	useEffect(() => {
		getBusinessPartners()
	}, [])

	const handleBusinessPartner = _.debounce((e) => {
		getBusinessPartners(e.target.value)
	}, 1000)

	const handleItem = _.debounce((e, i) => {
		getItems(e.target.value, i)
	}, 1000)

	useEffect(() => {
		setForm((prev) => ({ ...prev, DocumentLines: selectedItem }))
	}, [selectedItem])

	const handlePostBtnClick = () => {
		purchaseInvoicesPost()
	}

	const handleStartDateChange = (event) => {
		setForm((prev) => ({
			...prev,
			DocDate: moment(event.target.value).format('YYYY-MM-DD') + 'T00:00:00',
		}))
	}

	const handleCurrencyChange = (event) => {
		setForm((prev) => ({ ...prev, DocCurrency: event.target.value }))
	}

	const handleBusinessPartnerClick = (event) => {
		if (inputRef.current) {
			inputRef.current.value = event.target.innerText
		}
		setForm((prev) => ({
			...prev,
			CardName: event.target.innerText,
			CardCode: businessPartnersData.find(
				(item) => item.CardName === event.target.value,
			).value,
		}))
		setBusinessPartnersData([])
	}

	const handleAddItem = () => {
		setItems((prev) => [...prev, { ...initItem }])
		setItemsName((prev) => [...prev, ''])
	}

	const handleRemoveItem = (i) => {
		console.log(i)

		setItems((prev) => prev.filter((_, idx) => idx !== i))
		setItemsData([])
		setItemsName((prev) => {
			const newItemsName = [...prev]
			newItemsName.splice(i, 1)
			console.log(newItemsName)
			return newItemsName
		})
	}

	useEffect(() => {
		console.log(items)
		console.log(itemsName)
		console.log(getMe)
	}, [items])

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OITM'",
					// $select: 'Name,ValidValuesMD,TableName',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	useEffect(() => {
		userFieldsFn()
	}, [])

	const handleClickItem = (code, i) => {
		const selectedItem = itemsData.find((v) => v.ItemCode === code)
		const color =
			get(selectedItem, 'U_Color', '') !== ''
				? userFields
						?.find((item) => item.Name === 'Color')
						?.ValidValuesMD.find(
							(value) => get(selectedItem, 'U_Color', '') === value.Value,
						)?.Description || ''
				: ''

		const memory =
			get(selectedItem, 'U_Memory', '') !== ''
				? userFields
						?.find((item) => item.Name === 'Memory')
						?.ValidValuesMD.find(
							(value) => get(selectedItem, 'U_Memory', '') === value.Value,
						)?.Description || ''
				: ''

		const condition =
			get(selectedItem, 'U_Condition', '') !== ''
				? userFields
						?.find((item) => item.Name === 'Condition')
						?.ValidValuesMD.find(
							(value) => get(selectedItem, 'U_Condition', '') === value.Value,
						)?.Description || ''
				: ''

		const itemDetails = `${selectedItem.ItemName} ${color} ${memory} ${condition}`

		setCondition(condition)
		setBatteryLife()
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						ItemCode: code,
						UnitPrice: items.find((v) => v.ItemCode === code)?.UnitPrice,
						batteryLife:
							condition === 'NEW' || condition === 'Новый' ? '100%' : '',
					}
				}
				return item
			}),
		])

		setItemsName((prev) => {
			const newArray = [...prev]
			newArray[i] = itemDetails
			return newArray
		})
		setItemsData([])
	}

	const handleConditionChange = (event, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						Condition: event.target.value,
					}
				}
				return item
			}),
		])
	}

	const handleImeiPurch = (value, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						U_IMEI_PURCH: value,
					}
				}
				return item
			}),
		])
	}
	const handleSerialNumberChange = (event, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						SerialNumbers: event.target.value,
					}
				}
				return item
			}),
		])
	}
	const handleSerialNumber2Change = (event, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						SerialNumber2: event.target.value,
					}
				}
				return item
			}),
		])
	}

	const handlePriceChange = (event, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						UnitPrice: event.target.value,
					}
				}
				return item
			}),
		])
	}
	const handleRegionChange = (event, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						U_Item_Region: event.target.value,
					}
				}
				return item
			}),
		])
	}

	const handleWarrantyChange = (event, i) => {
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						U_warranty: event.target.value,
					}
				}
				return item
			}),
		])
	}

	const handleBateryLifeChange = (val, i) => {
		// console.log(val)
		setItems((prev) => [
			...prev.map((item, idx) => {
				if (idx === i) {
					return {
						...item,
						batteryLife: val,
					}
				}
				return item
			}),
		])
	}

	return (
		<Layout>
			<CreatePurchases>
				<div className="container">
					<div className="flex gap-4 items-start">
						<div className="flex gap-2 items-start">
							<div className="searchable-select">
								<div className="flex flex-col gap-2">
									<p className="text-gray-800">Поставщик</p>
									<div className="flex items-center">
										<input
											className={
												'border-[1px] border-[#DFE2E9"] p-1 rounded-md pl-4'
											}
											ref={inputRef}
											type="text"
											onChange={handleBusinessPartner}
											placeholder="Бизнес партнер"
										/>
										{loadingBusinessPartners && <ClipLoader size={10} />}
									</div>
								</div>
								{!!businessPartnersData.length && (
									<div className="searchable-select-list">
										{businessPartnersData.map((item) => {
											return (
												<div
													key={item.value}
													className="searchable-select-item"
													onClick={handleBusinessPartnerClick}
												>
													{item.label}
												</div>
											)
										})}
									</div>
								)}
							</div>
							<button
								className="bg-[#0A4D68] text-white px-4 rounded-md py-1 mt-8"
								onClick={() => createUserRef.current?.open()}
							>
								+
							</button>
						</div>
						<div className="flex flex-col gap-2">
							<p className="text-gray-800">Дата</p>
							<DatePicker
								value={date}
								format="DD-MM-YYYY"
								onChange={(date) => setDate(date)}
								className="border-[1px] border-[#DFE2E9] p-1 rounded-md"
							/>
						</div>
						<div className="flex flex-col gap-2">
							<p className="text-gray-800">Валюта</p>
							<select
								className={
									'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
								}
								onChange={handleCurrencyChange}
							>
								<option>USD</option>
								<option>UZS</option>
							</select>
						</div>
					</div>
					<div className="overflow-y-auto my-8">
						<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
							<thead className="text-xs text-gray-700 uppercase bg-gray-50 "></thead>
							<tbody>
								{items.map((v, i) => {
									return (
										<tr className="bg-white  hover:bg-gray-50 " key={i}>
											<td className="px-6 py-4 border">
												<div className="searchable-select">
													<div className={'searchableHead'}>
														<div>
															<p>Наименование товара</p>
															<input
																className={
																	'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md w-[300px]'
																}
																type="text"
																onChange={(event) => {
																	handleItem(event, i)
																	setItemsName((prev) => {
																		const newArray = [...prev]
																		newArray[i] = event.target.value
																		return newArray
																	})
																}}
																placeholder="Наименование товара"
																value={itemsName[i]}
															/>
															{!!itemsData.length && i === whichInput && (
																<div className="searchable-select-list">
																	{itemsData.map((v) => {
																		const itemName = get(v, 'ItemName', '')
																		const itemCode = get(v, 'ItemCode', '')

																		const color =
																			get(v, 'U_Color', '') !== ''
																				? userFields
																						?.find(
																							(item) => item.Name === 'Color',
																						)
																						?.ValidValuesMD.find(
																							(value) =>
																								get(v, 'U_Color', '') ===
																								value.Value,
																						)?.Description || ''
																				: ''

																		const memory =
																			get(v, 'U_Memory', '') !== ''
																				? userFields
																						?.find(
																							(item) => item.Name === 'Memory',
																						)
																						?.ValidValuesMD.find(
																							(value) =>
																								get(v, 'U_Memory', '') ===
																								value.Value,
																						)?.Description || ''
																				: ''

																		const condition =
																			get(v, 'U_Condition', '') !== ''
																				? userFields
																						?.find(
																							(item) =>
																								item.Name === 'Condition',
																						)
																						?.ValidValuesMD.find(
																							(value) =>
																								get(v, 'U_Condition', '') ===
																								value.Value,
																						)?.Description || ''
																				: ''

																		const formattedValue = [
																			itemName,

																			color,
																			memory,
																			condition,
																			itemCode,
																		]
																			.filter(Boolean) // Filter out empty or falsey values
																			.join(' ') // Join with ', ' only if the value is present

																		return (
																			<div
																				onClick={() =>
																					handleClickItem(v.ItemCode, i)
																				}
																				key={v.ItemCode}
																				className="searchable-select-item "
																			>
																				{formattedValue}
																			</div>
																		)
																	})}
																</div>
															)}
														</div>
													</div>
												</div>
											</td>
											<td className="px-6 border py-4">
												<p>Регион</p>
												<input
													placeholder="Регион"
													className={
														'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md'
													}
													value={v.U_Item_Region}
													onChange={(event) => {
														if (event.target.value.length < 6) {
															handleRegionChange(event, i)
														}
													}}
												/>
											</td>
											<td className="px-6 border py-4">
												<p>ИМЕИ</p>
												<input
													placeholder="ИМЕИ"
													className={
														'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md'
													}
													value={v.SerialNumbers}
													onChange={(event) => {
														handleSerialNumberChange(event, i)
													}}
												/>
											</td>
											{/* <td className="px-6 border py-4">
												<p>ИМЕИ2</p>
												<input
													placeholder="ИМЕИ2"
													className={
														'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md'
													}
													value={v.SerialNumber2}
													onChange={(event) => {
														handleSerialNumber2Change(event, i)
													}}
												/>
											</td> */}
											<td className="px-6 border py-4">
												<div>
													<p>Ёмкость</p>
													<input
														className={
															'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md w-[150px]'
														}
														type="text"
														onChange={(event) => {
															handleBateryLifeChange(event.target.value, i)
														}}
														placeholder="Ёмкость"
														value={v.batteryLife}
														disabled={
															condition === 'NEW' || condition === 'Новый'
														}
													/>
												</div>
											</td>
											<td className="px-6 border py-4">
												<p>Цена</p>
												<input
													className={
														'border-[1px] border-[#DFE2E9"] p-1 mt-2 rounded-md'
													}
													type="number"
													placeholder="Цена"
													onChange={(event) => {
														handlePriceChange(event, i)
													}}
													value={v.UnitPrice}
												/>
											</td>
											<td className="px-6 border py-4">
												<p>Гарантия</p>
												<select
													className={
														'border-[1px] border-[#DFE2E9"] w-[100px] p-1 mt-2 rounded-md'
													}
													onChange={(event) => {
														handleWarrantyChange(event, i)
													}}
													value={v.U_warranty}
												>
													{' '}
													<option key={0} value={''}>
														{''}
													</option>
													{[...Array(24)].map((_, index) => (
														<option key={index + 1} value={index + 1}>
															{index + 1}
														</option>
													))}
												</select>
											</td>
											{i === items.length - 1 ? (
												<button
													onClick={handleAddItem}
													className="p-4 bg-[#0A4D68] text-white rounded-md ml-2 mt-4"
												>
													+
												</button>
											) : null}
											{items.length > 1 ? (
												<button
													onClick={() => handleRemoveItem(i)}
													className="p-4 bg-red-600 text-white rounded-md ml-2 mt-4"
												>
													-
												</button>
											) : null}
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'end',
							paddingRight: '50px',
							paddingTop: '30px',
						}}
					>
						<Button isLoading={btnLoading} onClick={handlePostBtnClick}>
							Создать
						</Button>
					</div>
				</div>
			</CreatePurchases>
			<>
				<CreateSuppliersModal
					isLoading={createUserLoading}
					getRef={(r) => (createUserRef.current = r)}
					onClose={() => {
						getBusinessPartners()
					}}
				/>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default CreatePurchasesPage
