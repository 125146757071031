import React, { memo, useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import { get } from 'lodash'
import api, { customPhotoUploadApi } from '../../../../api'
import { ErrorModal } from '../../../../components/Modal'
import CreateUserStyle from '../../../../components/Modal/ScoreUserCreate/CreateUserStyle'
import Button from '../../../../components/Button'
import { useSelector } from 'react-redux'
import customMainApi from '../../../../api'
import DistrictData from '../../../../components/Modal/ScoreUserCreate/Regions/districts.json'
import VillageData from '../../../../components/Modal/ScoreUserCreate/Regions/villages.json'
import ClipLoader from 'react-spinners/ClipLoader'
import formatPhoneNumber from '../../../../helpers/PhoneWithSpaces'
import { message, Input } from 'antd'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		height: '90%',

		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		border: 'none',
		borderRadius: 15,
		overflowX: 'auto',
	},
}

const UpdateUserModal = (props) => {
	const { getRef = () => {}, onUpdated = () => {}, onClose = () => {} } = props
	const { getMe } = useSelector((state) => state.main)

	const errorRef = useRef()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [cardCode, setCardCode] = useState('')
	const [score, setScore] = useState('')
	const [katm, setKatm] = useState('')
	const [mib, setMib] = useState('')
	const [nasiya, setNasiya] = useState('')
	const [passport, setPassport] = useState('')
	const [phone, setPhone] = useState('')
	const [workplace, setWorkplace] = useState('')
	const [U_Created, setU_Created] = useState('')
	const [employeeData, setEmployeeData] = useState([])

	const [male, setMale] = useState('')
	const [userName, setUserName] = useState('')
	const [userSureName, setUserSureName] = useState('')
	const [userFathersName, setUserFathersName] = useState('')
	const [disData, setDisData] = useState([])
	const [villData, setVillData] = useState([])
	const [region, setRegion] = useState('')
	const [addresses, setAddress] = useState([])

	const [districtData, setDistrictData] = useState([])
	const [regionsData, setRegionsData] = useState([])
	const [citiesData, setCitiesData] = useState([])

	const [district, setDistrict] = useState('')
	const [village, setVillage] = useState('')
	const [comment, setComment] = useState('')
	const [frozen, setFrozen] = useState('')
	const [userFields, setUserFields] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [bpLoading, setBpLoading] = useState(false)
	const [salary, setSalary] = useState('')
	const [ageData, setAgeData] = useState('')
	const [path, setPath] = useState([])
	const [language, setLanguage] = useState('')
	const [bornAdress, setBornAdress] = useState('')
	const role =
		get(getMe, 'Department2.Name', '') === 'Boshqaruv' ||
		get(getMe, 'Department2.Name', '') === 'Scoring'

	useEffect(() => {
		fetchRegions()
	}, [])

	const getEmployess = (id) => {
		try {
			api(`EmployeesInfo(${id})`).then((res) => {
				console.log(JSON.parse(res.data).value)
				const data = JSON.parse(res.data)
				setU_Created(
					`${get(data, 'FirstName', '')} ${get(data, 'LastName', '')} `,
				)
			})
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		const ref = {
			open: (data) => {
				console.log(data.U_Telephone)
				setIsOpenModal(true)
				setCardCode(
					get(data, 'CardCode', null) !== null ? get(data, 'CardCode', '') : '',
				)

				getUserInfo(get(data, 'CardCode', ''))
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	useEffect(() => {
		fetchRegions()
	}, [])

	const getUserInfo = (cardCode) => {
		setBpLoading(true)
		try {
			api(
				`BusinessPartners('${cardCode}')?$select=CardName,U_PhotoId,U_Salary,U_age_data,FreeText,Frozen,U_workplace,U_Telephone,U_Respawn,U_Language,U_Gender,U_PS,U_KATM,U_Score,U_MIB,U_Nasiya,U_Created,BPAddresses`,
			).then((res) => {
				// console.log(JSON.parse(res.data))
				const data = JSON.parse(res.data)
				setUserName(
					get(data, 'CardName', null) !== null
						? get(data, 'CardName', '').split(' ')[1]
						: '',
				)
				setUserFathersName(
					get(data, 'CardName', null) !== null
						? get(data, 'CardName', '').split(' ')[2] +
								' ' +
								(get(data, 'CardName', '').split(' ')[3] || '')
						: '',
				)
				setUserSureName(
					get(data, 'CardName', null) !== null
						? get(data, 'CardName', '').split(' ')[0]
						: '',
				)
				const bpAddresses = get(data, 'BPAddresses', [])

				setWorkplace(get(data, 'U_workplace', ''))
				setPhone(formatPhoneNumber(get(data, 'U_Telephone', '')))
				setMale(get(data, 'U_Gender', ''))
				setPassport(get(data, 'U_PS', ''))
				setScore(get(data, 'U_Score', ''))

				// const employee = employeeData.find(
				// 	(emp) => emp.EmployeeID === data?.U_Created || '',
				// )

				// const employeeName = employee
				// 	? `${employee.FirstName} ${employee.LastName}`
				// 	: ''
				// console.log(employeeName)
				const photoId = get(data, 'U_PhotoId', '')

				const photoPaths = photoId
					? photoId.includes(',')
						? photoId.split(',')
						: [photoId]
					: []

				const initialPath = photoPaths.map((path) => ({
					file: 'not empty',
					has: true,
					path,
					imgLoading: false,
				}))

				const finalPath =
					initialPath.length > 0
						? initialPath
						: [{ file: null, path: '', imgLoading: false }]

				setPath(finalPath)

				setLanguage(get(data, 'U_Language', ''))
				setComment(get(data, 'FreeText', '-'))
				setSalary(get(data, 'U_Salary', ''))
				setAgeData(get(data, 'U_age_data', ''))

				setAddress(
					Array.isArray(bpAddresses) && bpAddresses.length > 0
						? bpAddresses
						: [
								{
									AddressName: 1, // Auto-generate something here if needed
									RowNum: 0,
									BPCode: cardCode,
									U_CityCode: '',
									U_DistrictCode: '',
									U_RegionCode: '',
									StreetNo: '',
									BuildingFloorRoom: '',
								},
							],
					// :[]
				)

				setBornAdress(get(data, 'U_Respawn', ''))
				setFrozen(get(data, 'Frozen', ''))
				if (data?.U_Created) {
					getEmployess(data?.U_Created)
				} else {
					setU_Created('')
				}
			})
		} catch (error) {
			console.log(error)
		} finally {
			setBpLoading(false)
		}
	}

	const handleFileChange = (event, index) => {
		const updatedPath = [...path]
		updatedPath[index].file = event.target.files[0]
		setPath(updatedPath)
	}

	const uploadImg = async (index) => {
		const image = path[index]

		if (!image.file) {
			message.error('Фотография не выбрано')
			return
		}

		const formData = new FormData()
		formData.append('file', image.file)

		const generateRandomKey = () => {
			const characters =
				'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
			let randomKey = ''
			for (let i = 0; i < 16; i++) {
				randomKey += characters.charAt(
					Math.floor(Math.random() * characters.length),
				)
			}
			return randomKey
		}

		const randomKey = generateRandomKey()
		const title = 'YourTitle' // Replace with actual title

		formData.append('Title', title)
		formData.append('Key', randomKey)

		try {
			const updatedPath = [...path]
			updatedPath[index].imgLoading = true
			setPath(updatedPath)

			const res = await customPhotoUploadApi.post('assets/upload', formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})

			const { key } = res.data
			updatedPath[index].path = key
			setPath(updatedPath)

			message.success('Успешно загружено!')
		} catch (err) {
			message.error('Ошибка загрузки')
		} finally {
			const updatedPath = [...path]
			updatedPath[index].imgLoading = false
			setPath(updatedPath)
		}
	}

	// View the uploaded image
	const viewImage = async (index) => {
		const image = path[index]

		try {
			const res = await customPhotoUploadApi.get(
				`assets/download/${image.path}`,
				{
					responseType: 'blob',
				},
			)

			const url = window.URL.createObjectURL(
				new Blob([res.data], { type: 'image/png' }),
			)

			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'BP.png')
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		} catch (error) {
			message.error('Ошибка загрузки файла')
		}
	}

	// Add a new empty row for uploading images
	const addNewRow = () => {
		setPath([...path, { file: null, path: '', imgLoading: false }])
	}

	const deleteImage = async (index) => {
		const image = path[index]
		let url = `BusinessPartners('${cardCode}')`

		try {
			const res = await customPhotoUploadApi.delete(`assets/${image.path}`)
			const photoId = path
				.map((images) => images.path)
				.filter((p) => p !== image.path) // Correct filter syntax
				.join(',')
			await api.patch(url, {
				U_PhotoId: photoId,
			})
			deleteRow(index) // Remove the image from the state after deletion
			message.success('Успешно удалено!')
		} catch (error) {
			message.error('Ошибка удаления файла')
		}
	}

	// Delete a specific row
	const deleteRow = (index) => {
		setPath(path.filter((_, i) => i !== index))
	}

	const fetchDistrictsWithoutId = () => {
		api
			.get(`U_ADDRESS_DISCRTICT`, {
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			})
			.then((res) => {
				const items = JSON.parse(res.data).value
				console.log(items)
				setDistrictData(items)
				// console.log(res.data)
			})
	}

	const fetchDistricts = (cityCode) => {
		if (cityCode.trim()) {
			api
				.get(`U_ADDRESS_DISCRTICT?$filter=U_CityCode eq '${cityCode}'`)
				.then((res) => {
					const items = JSON.parse(res.data).value
					console.log(items)
					setDistrictData(items)
				})
				.catch((error) => {
					console.error('Error fetching districts:', error)
				})
		}
	}

	const fetchCities = (rgId) => {
		// ?$filter=U_RegionCode eq rgId
		if (rgId.trim()) {
			api
				.get(`U_ADDRESS_CITY?$filter=U_RegionCode eq '${rgId}'`)
				.then((res) => {
					const items = JSON.parse(res.data).value
					console.log(items)
					setCitiesData(items)
					// console.log(res.data)
				})
		}
	}

	const fetchCitiesWithoutId = () => {
		// ?$filter=U_RegionCode eq rgId

		api
			.get(`U_ADDRESS_CITY`, {
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			})
			.then((res) => {
				const items = JSON.parse(res.data).value
				console.log(items)
				setCitiesData(items)
				// console.log(res.data)
			})
	}

	const fetchRegions = () => {
		api.get('U_ADDRESS_REGION').then((res) => {
			const items = JSON.parse(res.data).value
			console.log(items)
			setRegionsData(items)
			// console.log(res.data)
		})
	}

	const handleAddressChange = (index, field, value) => {
		const newAddresses = addresses.map((address, i) => {
			if (i === index) {
				return { ...address, [field]: value }
			}
			return address
		})
		setAddress(newAddresses)
	}

	useEffect(() => {
		console.log(phone)
	}, [phone])

	const submit = () => {
		setIsLoading(true)
		let url = `BusinessPartners('${cardCode}')`
		const photoId = path
			.map((image) => image.path)
			.filter(Boolean)
			.join(',')
		api
			.patch(url, {
				U_workplace: workplace,
				CardName: `${userSureName} ${userName} ${userFathersName}`, //UserName, userSurname, userFathersName,
				U_Telephone: `${phone}`,
				Currency: '##', // Bydefault
				U_Gender: male, // Erkak Ayol
				U_PS: passport, //Pasport
				// U_Score: Number(score.split(' ')[0]),
				U_Respawn: bornAdress,
				Frozen: frozen,
				Valid: frozen === 'tNO' ? 'tYES' : 'tNO',
				U_Language: language,
				FreeText: comment, // Comment
				U_Created: get(getMe, 'EmployeeID', ''),
				U_Salary: salary,
				U_PhotoId: photoId,
				U_age_data: ageData,

				BPAddresses: addresses.map((address, idx) => ({
					AddressName: idx + 1, // Auto-generate something here if needed
					RowNum: address.RowNum,
					BPCode: address.BPCode,
					U_CityCode:
						address.U_CityCode && address.U_CityCode.length > 4
							? Number(address.U_CityCode.split(' - ')[1])
							: address.U_CityCode, // Village
					U_DistrictCode:
						address.U_DistrictCode && address.U_DistrictCode.length > 4
							? Number(address.U_DistrictCode.split(' - ')[1])
							: address.U_DistrictCode, // District
					U_RegionCode:
						address.U_RegionCode && address.U_RegionCode.length > 4
							? Number(address.U_RegionCode.split(' - ')[1])
							: address.U_RegionCode, // Region
					BuildingFloorRoom: address.BuildingFloorRoom,
					StreetNo: address.StreetNo,
				})),
			})
			.then(() => {
				onUpdated()
				setIsOpenModal(false)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => setIsLoading(false))
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OCRD'",
				},
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	useEffect(() => {
		userFieldsFn()
		fetchCitiesWithoutId()
		fetchDistrictsWithoutId()
	}, [])

	const getRegion = (text) => {
		let a = Number(text.split(' - ')[1])

		let changed_data = DistrictData.filter((v) => {
			return a === Number(get(v, 'region_id', 0))
		})
		setDisData(changed_data)
		setRegion(text)
		setDistrict('')
		setVillage('')
	}

	const getDistrict = (text) => {
		let a = Number(text.split(' - ')[1])

		let changed_data = VillageData.filter((v) => {
			return a === Number(get(v, 'district_id', 0))
		})
		setVillData(changed_data)
		setDistrict(text)
		setVillage('')
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CreateUserStyle>
				<div className="card">
					<p className="title">
						{'Изменить клиента'}
						{'!'}
					</p>
					{bpLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="flex flex-col gap-4">
								<div className="flex items-center gap-2">
									<div className="flex flex-col gap-2 items-start">
										<p>Имя</p>
										<input
											type="text"
											placeholder="Имя"
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
											}
											value={userName}
											onChange={(v) => setUserName(v.target.value)}
											disabled={!role}
										/>
									</div>
									<div className="flex flex-col gap-2 items-start">
										<p>Фамилия</p>
										<input
											type="text"
											placeholder="Фамилия"
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
											}
											disabled={!role}
											value={userSureName}
											onChange={(v) => setUserSureName(v.target.value)}
										/>
									</div>

									<div className="flex flex-col gap-2 items-start">
										<p>Отчество</p>
										<input
											type="text"
											placeholder="Отчество"
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
											}
											value={userFathersName}
											onChange={(v) => setUserFathersName(v.target.value)}
											disabled={!role}
										/>
									</div>
								</div>
								{addresses.map((address, index) => {
									const region = regionsData?.find(
										(value) => address.U_RegionCode === Number(value.Code),
									)
									const displayRegion = region ? `${region.Name}` : ''

									const city = citiesData?.find(
										(value) => address.U_CityCode === Number(value.Code),
									)
									const displayCity = city ? `${city.Name}` : ''

									const district = districtData?.find(
										(value) => address.U_DistrictCode === Number(value.Code),
									)
									const displayDistrict = district ? `${district.Name}` : ''

									return (
										<div className="flex flex-col gap-5 mt-4" key={index}>
											{index === 0 ? (
												<b>Адрес прописки</b>
											) : (
												<b>Адрес проживание</b>
											)}
											<hr className="w-full border-1 font-extrabold border-black" />

											<div className="flex items-center gap-2">
												<div className="flex flex-col gap-2 items-start">
													<p>Область</p>
													<input
														type="text"
														placeholder="Область"
														list="region"
														defaultValue={displayRegion}
														onChange={(v) => {
															getRegion(v.target.value)
															fetchCities(
																v.target.value.split(' - ')[1]
																	? v.target.value.split(' - ')[1]
																	: ' ',
															)
															handleAddressChange(
																index,
																'U_RegionCode',
																v.target.value,
															)
														}}
														className={
															'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
														}
														disabled={!role}
													/>
													<datalist id="region">
														{regionsData.map((v, i) => (
															<option key={i} value={`${v.Name} - ${v.Code}`} />
														))}
													</datalist>
												</div>
												<div className="flex flex-col items-start gap-2">
													<p>Город | Район</p>
													<input
														type="text"
														placeholder="Город | Район"
														list="village"
														defaultValue={displayCity}
														onChange={(v) => {
															setVillage(v.target.value)
															fetchDistricts(
																v.target.value.split(' - ')[1]
																	? v.target.value.split(' - ')[1]
																	: ' ',
															)
															handleAddressChange(
																index,
																'U_CityCode',
																v.target.value,
															)
														}}
														className={
															'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
														}
														disabled={!role}
													/>
													<datalist id="village">
														{citiesData.map((v, i) => (
															<option key={i} value={`${v.Name} - ${v.Code}`} />
														))}
													</datalist>
												</div>
												<div className="flex flex-col gap-2 items-start">
													<p>Кишлак | Район</p>
													<input
														type="text"
														placeholder="Кишлак | Район"
														list="district"
														defaultValue={displayDistrict}
														onChange={(v) => {
															getDistrict(v.target.value)
															handleAddressChange(
																index,
																'U_DistrictCode',
																v.target.value,
															)
														}}
														disabled={!role}
														className={
															'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
														}
													/>
													<datalist id="district">
														{districtData.map((v, i) => (
															<option key={i} value={`${v.Name} - ${v.Code}`} />
														))}
													</datalist>
												</div>
											</div>
											<div className="flex items-center gap-2">
												<div className="flex flex-col gap-2 items-start w-full">
													<p>Название улицы</p>
													<input
														type="text"
														placeholder="Название улицы"
														value={address.StreetNo}
														onChange={(v) =>
															handleAddressChange(
																index,
																'StreetNo',
																v.target.value,
															)
														}
														disabled={!role}
														className={
															'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
														}
													/>
												</div>
												<div className="flex flex-col items-start gap-2 w-full">
													<p>Номер дома</p>
													<input
														type="text"
														placeholder="Номер дома"
														value={address.BuildingFloorRoom}
														disabled={!role}
														onChange={(v) =>
															handleAddressChange(
																index,
																'BuildingFloorRoom',
																v.target.value,
															)
														}
														className={
															'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
														}
													/>
												</div>
											</div>
										</div>
									)
								})}
								<div className="flex items-center gap-2">
									<div className="flex flex-col gap-2 items-start w-full">
										<p>Паспорт/ПИНФЛ</p>
										<input
											type="text"
											placeholder="Паспорт/ПИНФЛ"
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
											}
											value={passport}
											disabled={!role}
											onChange={(v) => setPassport(v.target.value)}
										/>
									</div>
									<div className="flex flex-col gap-2 items-start w-full">
										<p>Телефон</p>
										<input
											type="text"
											placeholder="Телефон"
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
											}
											value={phone}
											disabled={!role}
											onChange={(v) => setPhone(v.target.value)}
										/>
									</div>
								</div>{' '}
								<div className="flex items-center gap-2">
									<div className="flex flex-col gap-2 items-start w-full">
										<p>Место рождения</p>
										<input
											type="text"
											placeholder="Место рождения"
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
											}
											value={bornAdress}
											disabled={!role}
											onChange={(v) => setBornAdress(v.target.value)}
										/>
									</div>

									<div className="flex flex-col gap-2 items-start w-full">
										<p>Дата рождение</p>
										<input
											type="date"
											placeholder="Дата рождение"
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
											}
											value={ageData}
											disabled={!role}
											onChange={(v) => setAgeData(v.target.value)}
										/>
									</div>
								</div>
								<div className="flex items-center gap-2">
									<div className="flex flex-col items-start gap-2 w-full">
										<p>Зарплата</p>
										<input
											type="text"
											placeholder="Зарплата"
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
											}
											value={salary}
											disabled={!role}
											onChange={(v) => setSalary(v.target.value)}
										/>
									</div>
									<div className="flex flex-col items-start gap-2 w-full">
										<p>Место работы</p>
										<input
											type="text"
											placeholder="Место работы"
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
											}
											value={workplace}
											disabled={!role}
											onChange={(v) => setWorkplace(v.target.value)}
										/>
									</div>
								</div>
							</div>

							<div className="flex items-center  mt-5 gap-2 ">
								<div className="flex flex-col gap-2  items-start w-full">
									<p>Статус</p>
									<select
										placeholder="Статус"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 w-full'
										}
										value={score}
										disabled={!role}
										onChange={(v) => setScore(v.target.value)}
									>
										{userFields
											?.find((item) => item.Name === 'Score')
											?.ValidValuesMD.map((value) => (
												<option value={value.Value}>
													{' '}
													{value.Value} - {value.Description}
												</option>
											))}
									</select>
								</div>
								<div className="flex flex-col items-start gap-2 w-full">
									<p>Коментария</p>
									<input
										type="text"
										placeholder="Коментария"
										value={comment}
										disabled={!role}
										onChange={(e) => {
											setComment(e.target.value)
										}}
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
										}
									/>
								</div>
							</div>
							<div className="flex items-center mt-5 gap-2">
								<div className="flex flex-col gap-2 items-start w-full">
									<p>Язык отправки смс :</p>
									<select
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full '
										}
										value={language}
										disabled={!role}
										onChange={(e) => setLanguage(e.target.value)}
									>
										<option value="">{''}</option>

										{userFields
											?.find((item) => item.Name === 'Language')
											?.ValidValuesMD.map((value) => (
												<option value={value.Value}>{value.Description}</option>
											))}
									</select>
								</div>
								<div className="flex flex-col items-start gap-2  w-full">
									<p>Создатель</p>
									<input
										type="text"
										// placeholder="Коментария"
										value={U_Created}
										disabled
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full  '
										}
									/>
								</div>
							</div>

							<div className="mt-10">
								{path.map((image, index) =>
									image.has ? (
										image.path && (
											<div
												key={index}
												className="flex w-full items-center gap-3 mt-5 mb-10"
											>
												<Button
													onClick={() => viewImage(index)}
													className="h-12 w-[100px] rounded-3xl bg-[#0A4D68] text-white"
												>
													Скачать
												</Button>
												<button
													onClick={() => deleteImage(index)}
													disabled={!role}
													className="h-[40px] w-[150px] rounded-lg p-2 bg-red-500 text-white"
												>
													Удалить
												</button>
												{index === path.length - 1 && (
													<Button
														onClick={addNewRow}
														disabled={!role}
														className="h-12 w-[100px] rounded-3xl bg-[#0A4D68] text-white"
													>
														Добавить
													</Button>
												)}
											</div>
										)
									) : (
										<div
											key={index}
											className="flex w-full items-start flex-col gap-2 mt-5 mb-10"
										>
											<div>
												<p className="font-bold">Фото-{index + 1}</p>
											</div>
											<div className="flex items-center gap-3">
												<Input
													type="file"
													disabled={!role}
													onChange={(e) => handleFileChange(e, index)}
													className="w-full"
												/>
												<Button
													onClick={() => uploadImg(index)}
													isLoading={image.imgLoading}
													disabled={!role}
													className="h-12 w-[100px] rounded-3xl bg-[#0A4D68] text-white"
												>
													Загрузить
												</Button>
												{image.path && (
													<>
														<Button
															onClick={() => viewImage(index)}
															className="h-12 w-[100px] rounded-3xl bg-[#0A4D68] text-white"
														>
															Скачать
														</Button>
														<button
															onClick={() => deleteImage(index)}
															disabled={!role}
															className="h-[40px] w-[150px] rounded-lg p-2 bg-red-500 text-white"
														>
															Удалить
														</button>
													</>
												)}
												{index !== 0 && (
													<button
														onClick={() => deleteRow(index)}
														disabled={!role}
														className="h-[40px] w-[100px] rounded-lg p-2 bg-red-500 text-white"
													>
														Отмена
													</button>
												)}
												{index === path.length - 1 && (
													<Button
														onClick={addNewRow}
														disabled={!role}
														className="h-12 w-[100px] rounded-3xl bg-[#0A4D68] text-white"
													>
														Добавить
													</Button>
												)}
											</div>
										</div>
									),
								)}
							</div>

							<div className="flex items-center  mt-5 my-5">
								<div className="flex items-center  gap-7 w-full">
									<h1>Активный :</h1>

									<div className="flex  gap-1">
										<input
											type="radio"
											id="yes"
											name="frozen"
											value="Да"
											disabled={!role}
											checked={frozen === 'tNO'}
											onChange={(v) =>
												setFrozen(v.target.value === 'Да' ? 'tNO' : 'tYES')
											}
										/>

										<label htmlFor="yes">Да</label>
									</div>

									<div className="flex gap-1">
										<input
											type="radio"
											id="no"
											name="frozen"
											value="Нет"
											disabled={!role}
											checked={frozen === 'tYES'}
											onChange={(v) =>
												setFrozen(v.target.value === 'Нет' ? 'tYES' : 'tNO')
											}
										/>
										<label htmlFor="no">Нет</label>
									</div>
								</div>
								<div className="flex gap-4 items-center w-full">
									<div className="flex gap-1">
										<input
											type="radio"
											id="male"
											name="fav_language"
											checked={male === 'Male'}
											disabled={!role}
											onChange={(v) => {
												setMale(v.target.value)
												console.log(v.target.value)
											}}
											value="Male"
										/>

										<label htmlFor="male">Мужчина</label>
									</div>
									<div className="flex gap-1">
										<input
											type="radio"
											id="female"
											name="fav_language"
											disabled={!role}
											checked={male === 'Female'}
											onChange={(v) => {
												setMale(v.target.value)
												console.log(v.target.value)
											}}
											value="Female"
										/>
										<label htmlFor="female">Женщина</label>
									</div>
								</div>
							</div>

							{role ? (
								<div className="centerCard">
									<button
										className="bg-red-500 text-white py-2 px-4 rounded-md btn"
										onClick={() => {
											onClose()
											setIsOpenModal(false)
										}}
									>
										{'Нет'}
									</button>
									<Button
										isLoading={isLoading}
										className={'btn'}
										// disabled={
										// 	// userName.length <= 0 ||
										// 	// userSureName.length <= 0 ||
										// 	// userFathersName.length <= 0 ||
										// 	// region.length <= 0 ||
										// 	// district.length <= 0 ||
										// 	// village.length <= 0 ||
										// 	// passportAdress.length <= 0 ||
										// 	// liveAdress.length <= 0 ||
										// 	// cardCode.length <= 0 ||
										// 	// passport.length <= 0 ||
										// 	// phone.length <= 0 ||
										// 	// workplace.length <= 0 ||
										// 	// male.length <= 0
										// }
										onClick={submit}
									>
										{'Да'}
									</Button>
								</div>
							) : (
								''
							)}
						</>
					)}
				</div>
			</CreateUserStyle>
			<ErrorModal getRef={(r) => (errorRef.current = r)} />
		</Modal>
	)
}

export default memo(UpdateUserModal)
