import React, { useEffect, useRef, useState } from 'react'
import ConstantConsumptionStyle from './ConstantConsumption'
import Button from '../../../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi } from '../../../../api'
import Layout from '../../../../components/Layout'
import { ErrorModal } from '../../../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { isAxiosError } from 'axios'
import ConfirmModal from '../../../../components/ConfirmModal'
import { get } from 'lodash'
import { COMPANY_NAME } from '../../../../config'
import moment from 'moment/moment'
import { useSelector } from 'react-redux' // Adjust the import path accordingly

const ConstantConsumptionPage = () => {
	const { getMe } = useSelector((state) => state.main)
	const navigation = useNavigate()
	const inputRef = useRef(null)
	const [isLoading, setIsLoading] = useState(false)
	const [date, setDate] = useState(new Date().toISOString().split('T')[0])
	const errorRef = useRef()
	const [currency, setCurrency] = useState('USD')
	const [cashAccountsData, setCashAccountsData] = useState([])
	const [spendAccountsData, setSpendAccountsData] = useState([])
	const [formData, setFormData] = useState({
		CardCode: '',
		CashAccount: '',
		CashSum: 0,
		AccountCode: '',
		cashFlow: '',
	})
	const [btnLoading, setBtnLoading] = useState(false)
	const [remarks, setRemarks] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const [confirmationMessage, setConfirmationMessage] = useState('')
	const [userFields, setUserFields] = useState([])

	const handleSpendAccountClick = (event) => {
		if (inputRef.current) {
			inputRef.current.value = event.target.innerText
		}
		setFormData((prev) => ({
			...prev,
			CardCode: event.target.value,
		}))
	}

	const cashAccounts = async () => {
		const data = await api(
			`ChartOfAccounts?$select=Code,Name&$filter=startswith(Code, '5') and ExternalCode eq '1' and (AcctCurrency eq '${currency}' or AcctCurrency eq '%23%23')`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			},
		).then((res) => {
			return JSON.parse(res.data).value
		})
		setCashAccountsData(data)
		setFormData((prev) => ({
			...prev,
			CashAccount: data[0]?.Code,
		}))
	}

	const userFieldsFn = () => {
		api
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'ORCT'",
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}
	const typeSpend = async () => {
		const data = await api(
			`ChartOfAccounts?$select=Code,Name&$filter=startswith(Code, '94') and ExternalCode eq '1' and (AcctCurrency eq '${currency}' or AcctCurrency eq '%23%23')`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			},
		).then((res) => {
			return JSON.parse(res.data).value
		})
		setSpendAccountsData(data)
		setFormData((prev) => ({
			...prev,
			CardCode: data[0]?.Code,
		}))
	}

	useEffect(() => {
		cashAccounts()
		typeSpend()
		userFieldsFn()
	}, [currency])

	const submitForm = () => {
		setConfirmationMessage([
			{
				name: 'Дата:',
				value: date,
			},
			{
				name: 'Валюта:',
				value: currency,
			},
			{
				name: 'Денежный счет:',
				value: `${cashAccountsData.find((item) => item.Code === formData.CashAccount).Name}, ${formData.CashAccount}`,
			},
			{
				name: 'Счет расхода:',
				value: `${spendAccountsData.find((item) => item.Code === formData.CardCode).Name}, ${formData.CardCode}`,
			},
			{
				name: 'Сумма:',
				value: `${formData.CashSum}`,
			},
			{
				name: 'ДДC:',
				value: `${formData.cashFlow}`,
			},
			{
				name: 'Комментарий:',
				value: `${remarks ? remarks : 'Нет'}`,
			},
		])
		setShowModal(true)
	}

	const handleConfirm = async () => {
		setShowModal(false)
		setBtnLoading(true)

		try {
			await api
				.post('VendorPayments', {
					DocDate: date + 'T00:00:00Z',
					DocType: 'rAccount',
					CardCode: formData.CardCode,
					CashAccount: formData.CashAccount,
					Remarks: remarks ? remarks : null,
					DocCurrency: currency,
					CashSum: formData.CashSum,
					U_CashFlow: formData.cashFlow,
					PaymentAccounts: [
						{
							AccountCode: formData.CardCode,
							SumPaid: formData.CashSum,
						},
					],
				})
				.then((res) => {
					window.open('/outgoing-payment', '_blank')
					return JSON.parse(res.data).value
				})
		} catch (err) {
			if (err) {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
				console.log(get(JSON.parse(err.response.data), 'error.message', ''))
			}
		} finally {
			setBtnLoading(false)
		}
	}

	const handleCancel = () => {
		setShowModal(false)
	}

	const handleDateChange = (event) => {
		setDate(event.target.value)
	}

	const handleCurrencyChange = (event) => {
		setCurrency(event.target.value)
		setSpendAccountsData([])
		setCashAccountsData([])
		if (inputRef.current) {
			inputRef.current.value = ''
		}
		setFormData((prev) => ({
			...prev,
			CashSum: null,
			CardCode: null,
		}))
	}

	const handleClickAccountCode = (event) => {
		setFormData((prev) => ({
			...prev,
			CashAccount: event.target.value,
		}))
	}

	// const handleClickCashFlow = (event) => {
	// 	setFormData((prev) => ({
	// 		...prev,
	// 		cashFlow: event.target.value,
	// 	}))
	// }

	const handleRemarks = (event) => {
		setRemarks(event.target.value)
	}

	return (
		<Layout>
			<ConstantConsumptionStyle>
				<div className="container">
					<p className="productTitle text-xl font-bold text-center py-8">
						Создать постоянные расходы
					</p>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<div>
							<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-6">
								<div className="flex flex-col">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Валюта
									</p>
									<select
										className={
											'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
										}
										onChange={handleCurrencyChange}
									>
										<option value="USD">USD</option>
										<option value="UZS">UZS</option>
									</select>
								</div>
								<div className="flex flex-col">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Счет расхода
									</p>
									<select
										value={formData.CardCode}
										className={
											'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
										}
										onChange={handleSpendAccountClick}
									>
										{spendAccountsData.map((item) => {
											return (
												<option key={item.Code} value={item.Code}>
													{item.Name} - {item.Code}
												</option>
											)
										})}
									</select>
								</div>
								<div className="flex flex-col">
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Дата
									</p>
									<input
										className={
											'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
										}
										type="date"
										defaultValue={date}
										onChange={handleDateChange}
									/>
								</div>
							</div>
							<div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:grid-cols-3">
								<div>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Денежный счет
									</p>
									<select
										value={formData.CashAccount}
										className={
											'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
										}
										onChange={handleClickAccountCode}
									>
										{cashAccountsData.map((item) => {
											return (
												<option key={item.Code} value={item.Code}>
													{item.Name} - {item.Code}
												</option>
											)
										})}
									</select>
								</div>
								<div>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Сумма
									</p>
									<input
										className={
											'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
										}
										type="number"
										onChange={(e) =>
											setFormData((prev) => ({
												...prev,
												CashSum: parseFloat(e.target.value),
											}))
										}
										value={formData.CashSum}
									/>
								</div>
								<div>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Комментарий
									</p>
									<textarea
										className={
											'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full flex-1'
										}
										onChange={handleRemarks}
									/>
								</div>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>ДДС</p>
								<select
									value={formData.cashFlow}
									className={
										'border-[1px] border-[#DFE2E9] p-1 rounded-md w-[33%]'
									}
									onChange={(e) => {
										setFormData((prev) => ({
											...prev,
											cashFlow: e.target.value, // Use e.target.value to get the selected option value
										}))
									}}
								>
									<option value=""></option>
									{userFields
										?.find((item) => item.Name === 'CashFlow')
										?.ValidValuesMD.map((value) => (
											<option key={value.Value} value={value.Value}>
												{value.Description}
											</option>
										))}
								</select>
							</div>
							<div className="flex justify-center py-4">
								<Button
									onClick={submitForm}
									loading={btnLoading}
									disabled={
										!formData.CashAccount ||
										!formData.CardCode ||
										!(+formData.CashSum > 0)
									}
								>
									Отправить
								</Button>
							</div>
						</div>
					)}
				</div>
			</ConstantConsumptionStyle>

			<ConfirmModal
				show={showModal}
				data={confirmationMessage}
				onConfirm={handleConfirm}
				onCancel={handleCancel}
			/>
			<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
		</Layout>
	)
}

export default ConstantConsumptionPage
