import React, { memo, useEffect, useRef, useState } from 'react'
import PaymentModalStyle from './PaymentModalStyle'
import api, { customFuncsApi } from '../../../api'
import numberWithSpaces from '../../../helpers/numberWithSpaces'
import Modal from 'react-modal'
import { get } from 'lodash'
import Button from '../../Button'
import { ErrorModal, RefundModal, SuccessModal } from '../index'
import { useSelector } from 'react-redux'
import { roundCurrency } from '../../../utils'
import { message } from 'antd'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '700px',
	},
}

const PaymentModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	loading,
	currencyRate,
	totalDebt,
	refundShow = true,
}) => {
	const { getMe } = useSelector((state) => state.main)
	const currentDate = new Date()
	const successModalRef = useRef()
	const errorRef = useRef()
	const year = currentDate.getFullYear()
	const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1 and pad with '0' if needed
	const day = String(currentDate.getDate()).padStart(2, '0')
	const formattedDate = `${year}-${month}-${day}`
	const refundRef = useRef()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const initDate = {
		dateC: formattedDate,
		amountUzs: null,
		amountUsd: null,
		payme: null,
		numberCard: null,
		terminal: null,
		dollar: currencyRate,
		smsSend: false,
		amountUsdCard: null,
	}
	const [form, setForm] = useState({ ...initDate })
	const [isRefund, setIsRefund] = useState(false)

	const totalPayment = roundCurrency(
		(+form.amountUsd || 0) +
			(+form.amountUzs || 0) / form.dollar +
			(+form.payme || 0) / form.dollar +
			(+form.terminal || 0) / form.dollar
			+ (+form.amountUsdCard || 0),
	)

	useEffect(() => {
		const ref = {
			open: () => {
				setIsOpenModal(true)
				setForm({ ...initDate })
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const handleRefund = ({ DocDate, DocRate, Type, CashSumUzs, CashSumUsd }) => {
		setIsRefund(true)
		customFuncsApi
			.post(`addMoneyChange`, {
				DocDate: DocDate,
				Type: Type,
				DocRate: DocRate,
				CashSumUzs: +CashSumUzs,
				CashSumUsd: +CashSumUsd,
				CashUzsAccount: getMe.U_CashUzsAccount,
				CashUsdAccount: getMe.U_CashAccount,
			})
			.then(() => {
				refundRef.current?.clear()
				refundRef.current?.close()
				successModalRef.current?.open('Платеж успешно произведен!')
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setIsRefund(false)
			})
	}

	const [accountsData, setAccountsData] = useState([])
	useEffect(() => {
		api
			.get(
				"ChartOfAccounts?$filter=startswith(Code, '57') and AcctCurrency eq 'UZS' and ExternalCode eq '1'&$select=Code,Name,AcctCurrency",
				{
					headers: {
						Prefer: 'odata.maxpagesize=10000',
					},
				},
			)
			.then((res) => {
				setAccountsData(JSON.parse(res.data).value)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}, [])

	const handleConfirm = () => {
		const payment = parseFloat(totalPayment)
		const debt = parseFloat(totalDebt)
		console.log('form.dollar=', Number(form.dollar))
		console.log('currencyRate=', currencyRate)
		console.log(Number(form.dollar) > Number(currencyRate) + 501)
		console.log(Number(form.dollar) > Number(currencyRate) - 501)

		if (payment > debt) {
			console.log(payment)
			console.log(debt)
			errorRef.current?.open('Сумма платежа превышает общую задолженность')
		} else if (
			Number(form.dollar) > Number(currencyRate) + 501 ||
			Number(form.dollar) < Number(currencyRate) - 501
		) {
			errorRef.current?.open(
				`Вы не можете ввести курс доллара больше 500 или меньше 500, чем ${currencyRate}.`,
			)
		} else {
			console.log(payment)
			console.log(debt)

			onConfirm({
				DocDate: form.dateC,
				CurrencyRate: form.dollar,
				AmountUsd: form.amountUsd,
				AmountUzs: form.amountUzs,
				AmountPayme: form.payme,
				NumberCard: form.numberCard,
				Terminal: form.terminal,
				smsSend: form.smsSend,
				AmountUsdCard: form.amountUsdCard,
			})
		}
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => {
				setIsOpenModal(false)
				setForm({ ...initDate })
			}}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<PaymentModalStyle>
				<div className="w-full">
					<h2 className="text-lg font-bold text-zinc-900 text-center mb-6">
						{'Добавить платеж'}
					</h2>
					<div className="grid grid-cols-1 gap-4">
						<div className="flex flex-col md:flex-row gap-4">
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								type={'date'}
								placeholder={'Дата'}
								defaultValue={form.dateC}
								onChange={(v) =>
									setForm((prevValue) => ({
										...prevValue,
										dateC: v.target.value,
									}))
								}
							/>
							<input
								className="border-[1px] border-[#DFE2E9] p-2 rounded-md w-full"
								placeholder="Курс"
								value={form.dollar}
								onChange={(v) => {
									setForm((prevValue) => ({
										...prevValue,
										dollar: v.target.value,
									}))
								}}
							/>
						</div>
						<input
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							placeholder={'Наличные USD'}
							onChange={(v) =>
								setForm((prevValue) => ({
									...prevValue,
									amountUsd: v.target.value,
								}))
							}
						/>
						<input
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							placeholder={'Карта USD'}
							onChange={(v) =>
								setForm((prevValue) => ({
									...prevValue,
									amountUsdCard: v.target.value,
								}))
							}
						/>
					
						<input
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							placeholder={'Наличные UZS'}
							onChange={(v) =>
								setForm((prevValue) => ({
									...prevValue,
									amountUzs: v.target.value,
								}))
							}
						/>
						<div className="flex flex-col md:flex-row gap-4">
							<input
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1 md:flex-1'
								}
								placeholder={'Карта Payme | Click'}
								onChange={(v) =>
									setForm((prevValue) => ({
										...prevValue,
										payme: v.target.value,
									}))
								}
							/>
							<div className="relative">
								<input
									type="text"
									list="productName"
									placeholder={'Номер карта '}
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md flex-1/2'
									}
									value={form.numberCard}
									onChange={(v) =>
										setForm((prevValue) => ({
											...prevValue,
											numberCard: v.target.value,
										}))
									}
								/>
								<datalist id="productName">
									{accountsData.map((v, i) => (
										<option
											key={i}
											label={`${get(v, 'Name', '')} , ${get(v, 'Code', '')}`}
											value={get(v, 'Code', '')}
										/>
									))}
								</datalist>
							</div>
						</div>
						<input
							className={'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'}
							placeholder={'Терминал'}
							onChange={(v) =>
								setForm((prevValue) => ({
									...prevValue,
									terminal: v.target.value,
								}))
							}
						/>
					</div>

					<div className={'my-7'}>
						<label
							className={
								'font-medium text-zinc-600 text-[14px] mb-1 flex flex-col gap-2 items-start'
							}
						>
							Общая сумма платежа
							<input
								value={numberWithSpaces(totalPayment) + ' USD'}
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-1/2'
								}
							/>
						</label>
					</div>
					<div className="flex items-center gap-3">
						<p>Отправить смс :</p>
						<input
							type="checkbox"
							placeholder={'Отправить смс'}
							onChange={(v) =>
								// console.log(v.target.value)
								setForm((prevValue) => ({
									...prevValue,
									smsSend: v.target.checked,
								}))
							}
						/>
					</div>
					{refundShow && (
						<div>
							<Button
								btnStyle={{ backgroundColor: '#243AB5' }}
								onClick={() => refundRef.current?.open()}
								hoverBtnStyle={{ backgroundColor: '#243AB5' }}
								className="my-4"
							>
								Сдачи
							</Button>
						</div>
					)}

					<div className="centerCard">
						<button
							className="btnN"
							onClick={() => {
								onClose()
								setForm({ ...initDate })
							}}
						>
							{'Нет'}
						</button>
						<button
							className="btnY disabled:opacity-50 disabled:cursor-not-allowed"
							onClick={handleConfirm}
							disabled={loading}
						>
							{'Да'}
						</button>
					</div>
				</div>
				<RefundModal
					currencyRate={currencyRate}
					getRef={(r) => (refundRef.current = r)}
					onConfirm={(form) => handleRefund(form)}
					onClose={() => refundRef.current?.close()}
					isLoading={isRefund}
				/>
				<SuccessModal getRef={(r) => (successModalRef.current = r)} />
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
			</PaymentModalStyle>
		</Modal>
	)
}

export default memo(PaymentModal)
