import React, { useEffect, useRef, useState } from 'react'
import { SuccessModal, PaymentModal, ErrorModal } from '../../components/Modal'
import ViewRecoveryItemStyle from './ViewRecoveryItemStyle'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import Layout from '../../components/Layout'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import api, { customFuncsApi } from '../../api'
import { AiOutlineSend } from 'react-icons/ai'
import { isEmpty } from 'lodash/lang'
import logo from '../../assets/images/logo.png'

import { roundCurrency } from '../../utils'
import moment from 'moment-timezone'
import CloseIcon from '../../assets/icons/close'
import ClipLoader from 'react-spinners/ClipLoader'
import numberWithSpaces from '../../helpers/numberWithSpaces'
import html2PDF from 'jspdf-html2canvas/index'
import formatPhoneNumber from '../../helpers/PhoneWithSpaces'
import { Empty } from 'antd'

const ViewRecoveryItem = () => {
	const { getMe } = useSelector((state) => state.main)

	const navigate = useNavigate()
	const location = useLocation()

	const successRef = useRef()
	const errorRef = useRef()
	const paymentRef = useRef()
	const commentRef = useRef(null)

	// const executer = get(location, 'state.executer', [])
	// const DocEntry = get(location, 'state.data.DocEntry', 0)
	// const InstlmntID = get(location, 'state.data.InstlmntID', 0)
	const query = new URLSearchParams(location.search)
	const DocEntry = JSON.parse(query.get('DocEntry'))
	const InstlmntID = JSON.parse(query.get('InstlmntID'))

	const [commentsLoading, setCommentsLoading] = useState(false)
	const [comment, setComment] = useState('')
	const [comments, setComments] = useState([])
	const [employeeID, setEmployeeID] = useState('')
	const [dollarLoading, setDollarLoading] = useState(false)
	const [uScore, setUScore] = useState('')
	const [UDueDate, setUDueDate] = useState('')
	const [isCommitLoading, setIsCommitLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [data, setData] = useState({})
	const [isComment, setIsComment] = useState({
		loading: false,
		order: -1,
	})
	const [dollar, setDollar] = useState(1)
	// const [deviceInfoData, setDeviceInfoData] = useState([])
	const [isLoading2, setIsLoading2] = useState(false)
	const [sumPay, setSumPay] = useState(0)
	const [payingSum, setPayingSum] = useState(0)
	const [tableData, setTableData] = useState([])
	const [tableInvoicePayments, setTableInvoicePayments] = useState([])
	const [tableData2, setTableData2] = useState([])
	const successModalRef = useRef()
	const [userFields, setUserFields] = useState([])
	const [employeeInfo, setEmployeeInfo] = useState([])

	useEffect(() => {
		// Scroll to bottom when comments change
		if (commentRef.current) {
			commentRef.current.scrollTop = commentRef.current.scrollHeight
		}
	}, [comments])

	useEffect(() => {
		getAllData()
		getComments()
		getInformationOfItem()
		getInvoicePayments()
		userFieldsFn()
		getEmployee()
	}, [])

	const userFieldsFn = () => {
		api
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OINV'",
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	const getInformationOfItem = () => {
		setIsLoading(true)
		customFuncsApi
			.get(`XsSql/getInstallments?docEntry=${DocEntry}`, {
				headers: {
					Prefer: 'odata.maxpagesize=50',
				},
			})
			.then((res) => {
				const resData = res.data.value || []
				setTableData2(resData)
				setTableData(resData)
				setIsLoading(false)
				let a = JSON.parse(JSON.stringify(resData))
				let allSum = 0
				let paySum = 0
				const now = moment().format('YYYYMMDD')
				for (let i = 0; i < a.length; i++) {
					if (!moment(a[i].DueDate, 'YYYYMMDD').isAfter(now, 'day')) {
						allSum = allSum + a[i].PaidToDate
						paySum = paySum + a[i].InsTotal
					}
				}
				setSumPay(allSum)
				setPayingSum(paySum)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}
	const getInvoicePayments = () => {
		setIsLoading(true)
		customFuncsApi
			.get(`XsSql/getInvoiceIncomingPayments?docEntry=${DocEntry}`, {
				headers: {
					Prefer: 'odata.maxpagesize=100',
				},
			})
			.then((res) => {
				setTableInvoicePayments(res.data.value)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getEmployee = () => {
		// ('${get(data, 'SlpCode', 0)}')?$select=FirstName,LastName
		api
			.get(`EmployeesInfo`, {
				params: {
					$filter: `FirstName eq 'Undiruvchi' or LastName eq 'Undiruvchi'`,
				},
			})
			.then((res) => {
				console.log(JSON.parse(res.data))
				setEmployeeInfo(JSON.parse(res.data))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getAllData = async () => {
		await customFuncsApi
			.get(`installment?DocEntry=${DocEntry}&InstlmntID=${InstlmntID}`)
			.then((res) => {
				setData(get(res, 'data', {}))
				setEmployeeID(get(res, 'data.U_Employee', 0))
				setUScore(get(res, 'data.U_Score', ''))
				setUDueDate(
					get(res, 'data.U_PromisedDate')
						? moment(get(res, 'data.U_PromisedDate')).format('YYYY-MM-DD')
						: '',
				)
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					navigate('/login', { replace: true })
				}
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getComments = () => {
		setCommentsLoading(true)
		api
			.get(
				`U_COMMENTS?$filter=U_Invoice eq ${DocEntry} and U_InstlmntID eq ${InstlmntID}&$orderby=U_CreateTime asc, Code desc`,
			)
			.then((res) => {
				setComments(get(JSON.parse(res.data), 'value', []))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setCommentsLoading(false)
			})
	}

	const deleteComments = (id, order) => {
		setIsComment((prevState) => ({ ...prevState, loading: true, order }))
		api
			.delete(`U_COMMENTS(${id})`)
			.then((res) => getComments())
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally((res) => {
				setIsComment((prevState) => ({
					...prevState,
					loading: false,
					order: -1,
				}))
			})
	}

	const sendComment = () => {
		setComment('')
		console.log(getMe)
		const date = moment().tz('Etc/GMT-5').format('YYYY-MM-DDTHH:mm:ssZ')

		const body = {
			U_Dunner: get(getMe, 'EmployeeID', ''),
			U_CreateDate: date,
			U_Invoice: get(data, 'DocEntry', ''),
			U_InstlmntID: InstlmntID,
			U_Text: comment,
			U_CreateTime: date,
			Name: `${get(getMe, 'FirstName', '')} ${get(getMe, 'LastName', '')}`,
		}
		api
			.post('U_COMMENTS', body)
			.then((res) => getComments())
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	// const createPayment = (sum, typeOfPayment, valuta, courseDollar) => {
	//   setIsLoading(true)
	//
	//   api
	//     .post(`IncomingPayments`, {
	//       CardCode: get(data, 'CardCode', 0),
	//       CashSum: Number(sum),
	//       CashAccount: getMe[typeOfPayment],
	//       BankChargeAmount: 0,
	//       DocCurrency: valuta,
	//       PaymentInvoices: [
	//         {
	//           DocEntry,
	//           InstallmentId: get(data, 'InstlmntID', 0),
	//           SumApplied:
	//             valuta === 'UZS'
	//               ? Number(sum) / Number(courseDollar)
	//               : Number(sum),
	//         },
	//       ],
	//     })
	//     .then(() => {
	//       successRef.current?.open('Платеж успешно создан')
	//       setIsLoading(false)
	//     })
	//     .catch((err) => {
	//       setIsLoading(false)
	//       errorRef.current?.open(
	//         get(JSON.parse(err.response.data), 'error.message', ''),
	//       )
	//     })
	// }

	const getUSD = () => {
		setDollarLoading(true)
		api
			.post(`SBOBobService_GetCurrencyRate`, {
				Currency: 'UZS',
				Date: moment(),
			})
			.then((res) => {
				setDollar(JSON.parse(res.data))
			})
			.catch((err) => {
				setDollar('error')
			})
			.finally(() => setDollarLoading(false))
	}
	useEffect(() => {
		getUSD()
	}, [])
	const createPayment = ({
		DocDate,
		CurrencyRate,
		AmountUsd,
		AmountUzs,
		AmountPayme,
		NumberCard,
		Terminal,
		smsSend,
		AmountUsdCard,
	}) => {
		setIsLoading(true)
		const payments = []
		if (AmountUsd) {
			payments.push({
				usdSum: AmountUsd,
				account: getMe.U_CashAccount,
				currency: 'USD',
				uzsSum: null,
			})
		}
		if (AmountUzs) {
			payments.push({
				usdSum: AmountUzs / CurrencyRate,
				account: getMe.U_CashUzsAccount,
				currency: 'UZS',
				uzsSum: AmountUzs,
			})
		}
		if (AmountPayme) {
			payments.push({
				usdSum: AmountPayme / CurrencyRate,
				account: NumberCard || getMe.U_CardAccount,
				currency: 'UZS',
				uzsSum: AmountPayme,
			})
		}
		if (Terminal) {
			payments.push({
				usdSum: Terminal / CurrencyRate,
				account: getMe.U_TerminalAccount,
				currency: 'UZS',
				uzsSum: Terminal,
			})
		}
		if (AmountUsdCard) {
			payments.push({
				usdSum: AmountUsdCard,
				account: getMe.U_CardUsdAccount,
				currency: 'USD',
				uzsSum: null,
			})
		}
		customFuncsApi
			.post('addIncomingPayments', {
				DocDate,
				DocEntry: DocEntry,
				CardCode: get(data, 'CardCode', null),
				DocRate: CurrencyRate,
				shouldSendSms: smsSend,
				U_CashFlow: '1',
				payments: payments,
			})
			.then((err) => {
				paymentRef.current?.close()
				successModalRef.current?.open('Платеж успешно произведен!')
				getInformationOfItem()
				getInvoicePayments()
			})
			.catch((err) => {
				paymentRef.current?.close()
				errorRef.current?.open(JSON.stringify(err.response.data))
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const cancel = () => {
		navigate('/recovery')
	}

	const allSaves = () => {
		setIsCommitLoading(true)
		const updateData = {
			U_Score: uScore,
			DocumentInstallments: [
				{
					InstallmentId: get(data, 'InstlmntID', 0),
					U_Comment: comment,
					U_Employee: employeeID > 0 ? employeeID : null,
					U_PromisedDate: `${UDueDate}T00:00:00.000Z`,
				},
			],
		}

		if (Number(uScore) > 1) {
			console.log(get(employeeInfo.value[0], 'EmployeeID', ''))
			updateData.U_Dunner = get(employeeInfo.value[0], 'EmployeeID', '')
		}

		api
			.patch(`Invoices(${get(data, 'DocEntry', 0)})`, updateData)
			.then(() => {
				successRef.current?.open('Заказ успешно сохранен')
				setIsCommitLoading(false)
			})
			.catch((err) => {
				setIsCommitLoading(false)
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const downloadCheck = (id, data) => {
		let element = document.getElementById('check' + id)

		html2PDF(element, {
			margin: 10,
			filename: `${'check' + id}.pdf`,
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 5, logging: true, dpi: 150, letterRendering: true },
			jsPDF: {
				unit: 'mm',
				format: 'a4',
				orientation: 'portrait',
				fontSize: 14,
			},
		})
	}

	return (
		<Layout>
			<ViewRecoveryItemStyle>
				<div className="flex gap-5 flex-col md:flex-row flex-1">
					<div className=" shadow-lg  p-2 w-full  border-[1px] border-[#DFE2E8] overflow-y-scroll flex flex-col 3xl:flex-row gap-5">
						<div className="grid gap-4 grid-cols-2 3xl:grid-cols-1  w-full h-fit p-2">
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>Код</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={get(data, 'DocNum', 'Kod')}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>ФИО</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={get(data, 'CardName', 'Покупатель')}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Продукт
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={get(data, 'Dscription', 'Товар')}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Поставщик
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={get(data, 'LastPurchase.CardName', '-')}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>Тел.</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={formatPhoneNumber(get(data, 'U_Telephone', 'Tel'))}
									disabled={true}
								/>
							</div>

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>ИМEИ</p>
								<input
									placeholder="IntrSerial"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={(get(data, 'IntrSerial', '-') || '').slice(-6)}
									disabled={true}
								/>
							</div>
							<div>
								{/*<p className={"comment"}>Комментарии</p>*/}
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Комментарий
								</p>
								<div className={'commentSection'}>
									<textarea
										name="comment"
										// id="comment"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										// cols="30"
										rows="1"
										value={comment}
										onChange={(v) => setComment(v.target.value)}
									/>
									<button
										className={'sendComment'}
										onClick={() => sendComment()}
										disabled={isEmpty(comment)}
									>
										<AiOutlineSend />
									</button>
								</div>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Дата платежа
								</p>
								<input
									type="string"
									placeholder="Цена"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									defaultValue={get(data, 'DueDate', 'Sana').slice(0, 10)}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Статус
								</p>
								<select
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									onChange={(v) => {
										setUScore(v.target.value)
									}}
									defaultValue={uScore}
									value={uScore}
								>
									<option value={''} key={0}></option>

									{userFields
										?.find((item) => item.Name === 'Score')
										?.ValidValuesMD.map((value) => (
											<option value={value.Value}>{value.Description}</option>
										))}
								</select>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Количество месяцев
								</p>
								<input
									type="number"
									placeholder="Цена"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={get(data, 'Installmnt', 1) - 1}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Текущий месяц
								</p>
								<input
									type="number"
									placeholder="Цена"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={roundCurrency(get(data, 'InstlmntID', 1)) - 1}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Срок платежа
								</p>
								<input
									type="date"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									onChange={(e) => {
										setUDueDate(e.target.value)
									}}
									value={UDueDate}
									defaultValue={UDueDate}
								/>
							</div>

							<div className="flex gap-3 flex-col lg:flex-row justify-end w-full col-span-2 2xl:col-span-1">
								<div className="flex gap-3">
									<Button
										btnStyle={{ backgroundColor: 'red' }}
										onClick={cancel}
									>
										Назад
									</Button>
								</div>
								<Button
									btnStyle={{ width: 250 }}
									hoverBtnStyle={{ width: 250 }}
									onClick={allSaves}
									isLoading={isCommitLoading}
								>
									Сохранять
								</Button>
							</div>
						</div>

						<div className="flex items-center justify-end py-5">
							{' '}
							<div className="flex flex-col items-center justify-center sm:flex-row gap-2 sm:gap-2">
								<p>
									<span className="font-bold text-zinc-900">Курс:</span>{' '}
									{new Intl.NumberFormat('fr-FR', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})
										.format(dollar)
										.replace(',', '.')}{' '}
									UZS
								</p>
								<p>
									<span className="font-bold text-zinc-900">Долг:</span>{' '}
									<span className="debt">
										-{(+payingSum - +sumPay).toFixed(2)}
									</span>{' '}
									USD
								</p>
								<p>
									<span className="font-bold text-zinc-900">Общий долг:</span>{' '}
									<span className="debt">
										{roundCurrency(
											+get(data, 'DocTotal', '0') -
												+get(data, 'PaidToDate', '0'),
										)}
									</span>
								</p>
								{get(getMe, 'Department2.Name', '') === 'Boshqaruv' ||
								get(getMe, 'Department2.Name', '') === 'Undiruvchi1' ? (
									<Button
										btnStyle={{ backgroundColor: '#243AB5' }}
										onClick={() => paymentRef.current?.open()}
										isLoading={isLoading || isLoading2 || dollarLoading}
										hoverBtnStyle={{ backgroundColor: '#243AB5' }}
									>
										Оплатить
									</Button>
								) : null}
							</div>
						</div>
						<div className="flex gap-7">
							<div>
								<h1 className={'font-bold mb-3'}>План платежа</h1>

								<table className="w-full text-sm text-left rtl:text-right text-black">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												N
											</th>
											<th scope="col" className="px-6 py-4">
												Дата
											</th>
											<th scope="col" className="px-6 py-4">
												Оплата
											</th>
											<th scope="col" className="px-6 py-4">
												Оплачено
											</th>
											{/* <th scope="col" className="px-6 py-4">
												Действие
											</th> */}
										</tr>
									</thead>
									<tbody>
										{tableData.length > 0 ? (
											tableData.map((v, i) => {
												let a = v.DueDate.split('')
												a.splice(4, 0, '-')
												a.splice(7, 0, '-')
												a.join()
												return (
													<tr
														className="bg-white border-b  hover:bg-gray-50 "
														key={i}
													>
														<td className="px-6 py-4 font-medium text-gray-900 ">
															{i}
														</td>
														<td className="px-6 py-4 whitespace-nowrap">{a}</td>
														<td className="px-6 py-4">
															{new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(Number(v.InsTotal))
																.replace(',', '.')}
														</td>
														<td className="px-6 py-4">
															{new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(Number(v.PaidToDate))
																.replace(',', '.')}
														</td>
													</tr>
												)
											})
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description="Нет информации!"
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="overflow-y-auto ">
								<h1 className={'font-bold mb-3'}>Факт платежа</h1>
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												N
											</th>
											<th scope="col" className="px-6 py-4">
												Дата
											</th>
											{/*<th scope="col" className="px-6 py-4">*/}
											{/*	Оплата*/}
											{/*</th>*/}
											<th scope="col" className="px-6 py-4">
												Оплачено
											</th>
											<th scope="col" className="px-6 py-4">
												Курс
											</th>
											<th scope="col" className="px-6 py-4">
												Имя счета
											</th>
											<th scope="col" className="px-6 py-4">
												Действие
											</th>
										</tr>
									</thead>
									<tbody>
										{tableInvoicePayments.length > 0 ? (
											tableInvoicePayments.map((v, i) => {
												const totalInUZS =
													Number(v.CashSum).toFixed(2) * Number(v.DocRate)
												return (
													<tr
														className="bg-white border-b  hover:bg-gray-50 "
														key={i}
													>
														<td className="px-8 py-6 font-medium text-gray-900 ">
															{v.InstId}
														</td>

														<td className="px-6 py-4 whitespace-nowrap">
															{moment(v.DocDate).format('DD-MM-YYYY')}
														</td>
														<td className="px-6 py-4">
															{new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(Number(v.CashSum))
																.replace(',', '.')}{' '}
														</td>
														<td className="px-6 py-4">
															{new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(Number(v.DocRate))
																.replace(',', '.')}{' '}
														</td>

														<td className="px-6 py-4 ">{v.AcctName}</td>
														<td className="px-6 py-4">
															<div className="flex items-center gap-2">
																<button
																	className={
																		'whitespace-nowrap bg-[#0A4D68] text-white p-2 rounded-md disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500 hover:bg-[#0A4D68] hover:text-white'
																	}
																	onClick={() => downloadCheck(i, v)}
																	disabled={
																		isLoading || isLoading2 || dollarLoading
																	}
																>
																	скачать
																</button>
																{/* <button
																className={
																	'whitespace-nowrap bg-[#0A4D68] text-white p-2 rounded-md disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500 hover:bg-[#0A4D68] hover:text-white'
																}
																onClick={() => downloadCheck(i, v)}
																disabled={
																	isLoading || isLoading2 || dollarLoading
																}
															>
																View
															</button> */}
															</div>
														</td>
														<div
															id={'check' + i}
															className={
																'absolute -top-[100%] -right-[100%] w-[210mm]'
															}
														>
															<div className="w-[600px] mx-auto mt-10 p-6 bg-white border border-gray-300 rounded-md shadow-md">
																<div className="flex items-center justify-between">
																	<div>{'  '}</div>

																	<div className="flex gap-5">
																		<h2 className="text-center text-[18px]  font-semibold mb-4">
																			Квитанция платежа кредита
																			<br />
																			Кредит тўлов бланкаси
																		</h2>
																		{/* </div>
																<div> */}
																		<img
																			src={logo}
																			className="w-[100px] h-[30px]"
																		/>
																	</div>
																</div>
																<div className={'flex justify-between'}>
																	<div className="flex flex-col whitespace-nowrap justify-between mb-4">
																		<div>
																			<div>№: {get(v, 'OINV.DocNum', '')}</div>
																		</div>
																		<div className="font-bold text-center"></div>
																	</div>
																	<div className="flex justify-between mb-4 items-end">
																		<div>
																			<div>Дата платежа:</div>
																			<div>Тўлов санаси:</div>
																			<div>Взнос номери:</div>
																		</div>
																		<div className="font-bold flex  flex-col">
																			<h2>
																				{moment(v.DocDate).format('DD/MM/YYYY')}
																			</h2>
																			<h2 className="ml-2">{v.InstId}</h2>
																		</div>
																	</div>
																</div>
																<div className="flex flex-col justify-between mb-4">
																	<div className="font-bold text-center">
																		{get(data, 'CardName', 'Покупатель')}
																	</div>
																	<div
																		className={
																			'border-t-[1px] border-black text-center mt-2'
																		}
																	>
																		Фамилия И.О. плательщика/Тўловчи Фамилия
																		И.Ш.:
																	</div>
																</div>
																<div className="flex mb-4 items-end">
																	<div>
																		<div>Тел. номер плательщика:</div>
																		<div>Тўловчи тел. рақами:</div>
																	</div>
																	<div className="font-bold border-b-[1px] pb-2 flex-1 border-black text-center">
																		{formatPhoneNumber(
																			get(data, 'U_Telephone', '-'),
																		)}
																	</div>
																</div>
																<div className="flex items-end mb-4">
																	<div>
																		<div>Наименование продукта:</div>
																		<div>Mahsulot nomi:</div>
																	</div>
																	<div className="font-bold border-b-[1px] pb-2 flex-1 border-black text-center">
																		{get(data, 'Dscription', '-')}
																	</div>
																</div>
																<div className="flex items-end mb-4">
																	<div>
																		<div>Сумма платежа:</div>
																		<div>Тўлов суммаси:</div>
																	</div>
																	<div className="font-bold border-b-[1px] pb-2 flex-1 border-black text-center">
																		{new Intl.NumberFormat('fr-FR', {
																			minimumFractionDigits: 2,
																			maximumFractionDigits: 2,
																		})
																			.format(totalInUZS)
																			.replace(',', '.')}
																	</div>
																</div>

																<div
																	className={
																		'flex justify-between item-start gap-4'
																	}
																>
																	<div className="flex items-end flex-1">
																		<div>
																			<div>Получатель платежа:</div>
																			<div>Тўловни қабул қилувчи:</div>
																		</div>
																		<div className="font-bold border-b-[1px] pb-2 flex-1 border-black">
																			{get(v, 'firstName', '')}{' '}
																			{get(v, 'lastName', '')}
																		</div>
																	</div>

																	<div className="flex justify-between items-end mt-6 min-w-[150px]">
																		<div>
																			<div className="font-semibold">
																				Подпись:
																			</div>
																			<div className="font-semibold">Имзо:</div>
																		</div>
																		<div
																			className={
																				'font-bold border-b-[1px] flex-1 border-black '
																			}
																		></div>
																	</div>
																</div>
															</div>
														</div>
													</tr>
												)
											})
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description="Нет информации!"
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="shadow-md w-full   md:w-[28%] border-[1px] border-[#DFE2E8]">
						<div className="p-4 shadow-md">
							Комментарии{' '}
							{commentsLoading.loading && (
								<ClipLoader
									loading={true}
									size={10}
									aria-label="Loading Spinner"
									data-testid="loader"
								/>
							)}
						</div>
						<div ref={commentRef} className="p-4 h-[85vh] overflow-y-scroll">
							{comments.map((v, i) => {
								return (
									<div className="mb-4">
										<p className={'font-medium text-zinc-400 text-sm mb-1'}>
											{moment(get(v, 'U_CreateTime', '')).format(
												'DD-MM-YYYY HH:mm',
											)}
											{'  '}
											{get(v, 'Name', '')}
											{isComment.loading && isComment.order === i && (
												<ClipLoader
													loading={true}
													size={10}
													aria-label="Loading Spinner"
													data-testid="loader"
												/>
											)}
										</p>

										<div className="inputCard2">
											<div className="p-3 pt-5 font-medium rounded-lg rounded-bl-none bg-[#29d2f5] text-white relative">
												<button
													className="absolute top-1 right-1 z-10 cursor-pointer"
													onClick={() => deleteComments(get(v, 'Code', ''), i)}
												>
													<CloseIcon className="text-white w-[20px] " />
												</button>
												{get(v, 'U_Text', '')}
											</div>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</ViewRecoveryItemStyle>
			<>
				{/*<PaymentModal*/}
				{/*  getRef={(r) => {*/}
				{/*    paymentRef.current = r*/}
				{/*  }}*/}
				{/*  onConfirm={(sum, typeOfPayment, valuta, courseDollar) =>*/}
				{/*    createPayment(sum, typeOfPayment, valuta, courseDollar)*/}
				{/*  }*/}
				{/*/>*/}
				{dollarLoading ? null : (
					<PaymentModal
						getRef={(r) => (paymentRef.current = r)}
						onConfirm={(form) => createPayment(form)}
						onClose={() => paymentRef.current?.close()}
						loading={isLoading}
						currencyRate={dollar}
						totalDebt={(+payingSum - +sumPay).toFixed(2)}
					/>
				)}
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
				<SuccessModal
					getRef={(r) => {
						successRef.current = r
					}}
				/>
				<SuccessModal
					getRef={(r) => (successModalRef.current = r)}
					onConfirm={() => successModalRef.current?.close()}
				/>
			</>
		</Layout>
	)
}

export default ViewRecoveryItem
